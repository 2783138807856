import React from "react";
import styles from "./TestCard.module.css";
import sharedStyles from "../../styles/shared.module.css";
import { AreaType, CityWithBBSimple, UserCityType } from "cp-server";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { Config } from "framework";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import Stat from "../../components/stat/Stat";
import { getDistance } from "../../data/utils/formating/DistanceFormating";
import { useGetCitiesQuery } from "../../state/api/ApiSlice";

type PropsType = { city: UserCityType };
export default function TestWrapper() {
  const path = useLocation().pathname;
  const cityId = path.substring(useLocation().pathname.lastIndexOf("/") + 1);
  const cityIds = [
    "aa8db54e-bf8f-47d8-a557-fce2cf0c9ae7",
    "81a42863-b7dc-4805-b732-c6a2d5318670",
  ]; // change to add more cities

  const { data: cities } = useGetCitiesQuery({});

  if (cities?.success) {
    const citiesData: Array<CityWithBBSimple> = cities.results.filter(
      (cities) => cityIds.includes(cities.area_id)
    );
    const userCities = citiesData.map(
      (cityData): UserCityType => ({
        ...cityData,
        type: AreaType.city,
        user_city_id: "",
        //@ts-ignore
        polygon: null,
        landmarks_visited: (
          parseInt(cityData.total_landmarks) -
          Math.round(Math.random() * parseInt(cityData.total_landmarks))
        ).toString(),
        percent_covered: (Math.random() * 20 + 20).toFixed(1),
        percent_covered_month: "0",
        neighbourhoods_visited: Math.round(
          (Math.random() * parseInt(cityData.total_neighbourhoods)) / 2
        ).toFixed(0),
        distance_covered: "256000",
        achievements_complete: (Math.round(Math.random() * 10) + 10).toString(),
        activities: (Math.round(Math.random() * 100) + 100).toString(),
      })
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.border}>
          {userCities.map((uc) => (
            <UserCity city={uc} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
function UserCity(props: PropsType) {
  const city = props.city;
  const custom = city.type === "custom";
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/admin/global_heatmap_thumb?area_id=${city.area_id})`,
          }}
        >
          {custom ? (
            <div className={classNames(styles.custom, sharedStyles.heading5)}>
              {LangManager.getLang(LangKeys.CITIES_CUSTOM)}
            </div>
          ) : null}
        </div>
        <div className={classNames(sharedStyles.heading2, styles.cityName)}>
          {city.name}
        </div>
        <div className={styles.statsContainer}>
          <Stat
            editable
            label={LangManager.getLang(LangKeys.CITIES_LANDMARKS_VISITED)}
            valueColor="var(--orange-2)"
            value={`${city.landmarks_visited || 0}/${city.total_landmarks}`}
            complete={
              city.landmarks_visited === city.total_landmarks &&
              city.total_landmarks !== "0"
            }
          />
          <Stat
            editable
            label={LangManager.getLang(LangKeys.CITIES_PERCENT)}
            valueColor="var(--orange-2)"
            value={`${city.percent_covered}%`}
            complete={parseInt(city.percent_covered) === 100}
          />{" "}
          <Stat
            editable
            label={LangManager.getLang(LangKeys.CITIES_NEIGHBOURHOODS)}
            valueColor="var(--orange-2)"
            value={
              parseInt(city.total_neighbourhoods)
                ? `${city.neighbourhoods_visited}/${city.total_neighbourhoods}`
                : " -- "
            }
            complete={
              city.neighbourhoods_visited === city.total_neighbourhoods &&
              city.total_neighbourhoods !== "0"
            }
          />
          <Stat
            editable
            label={LangManager.getLang(LangKeys.CITIES_ACHIEVEMENTS)}
            value={`${city.achievements_complete}`}
          />
          <Stat
            editable
            label={LangManager.getLang(LangKeys.CITIES_ACTIVITIES)}
            value={`${city.activities}`}
          />
          <Stat
            editable
            label={LangManager.getLang(LangKeys.CITIES_DISTANCE)}
            value={`${getDistance(city.distance_covered, 1)}`}
          />
        </div>
      </div>
    </div>
  );
}
