import i18n from "i18next";
import { initReactI18next } from "react-i18next";
export const defaultNS = "translation";

const en = {
  header: {
    home: "Home",
    dashboard: "Dashboard",
    myCities: "My Cities",
    explore: "Explore",
    liveMap: "Live Map",
    settings: "Settings",
  },
  support: {
    header: "Support",
  },
  dashboard: {
    groups: {
      noGroups: "You are not a member of any groups in",
    },
    freeTrial: {
      message: "Get 1 month of premium for free! ",
      message2: "If you subscribe before 31st July",
    },
    header: {
      report: "Report inaccurate paths",
      showLandmarks: "Show landmarks",
      contact: "Contact us",
      whatsNew: "What's new",
    },
  },
  popups: {
    deleteConfirm: {
      area: "Are you sure you want to delete",
      group: "Are you sure you want to leave",
      activity: "Are you sure you want to delete",
    },
    namePopup: {
      firstName: "First name",
      lastName: "Last name",
      message: "Please enter your name to show up on the leaderboards",
      updateMessage: "Update your name",
      confirm: "Confirm",
      cancel: "Cancel",
      fieldError: "Please fill in your first and last name",
    },
  },
  explore: {
    noResults: "No results",
    cities: "Cities",
    areas: "Areas",
    nationalParks: "National Parks",
    scenicAreas: "Scenic Areas",
    subscribers: "Subscribers",
  },
  groups: {
    noCreatedGroups: "You have not created any groups yet.",
    noJoinedGroups: "You are not a member of any groups yet.",
    howToJoin: "To join a group the group owner must send you an invite link.",
    inviteLink: "Invite link",
  },
  activityDetails: {
    delete: "Delete",
    strava: "View on Strava",
    neighbourhoods: "Neighbourhoods visited",
    landmarks: "Landmarks visited",
    firstVisit: "First visit!",
    previousVisits: "previous visits",
    notSupportedArea: "This activity is not in a supported area",
  },
  activity: {
    newGround: "New Ground",
    distance: "Distance",
    landmarks: "Landmarks",
    revisited: "Revisited ground",
    time: "Time",
  },
  settings: {
    personalDetails: "Personal Details",
    name: "name",
    activityDescriptionHeader: "Strava Activity Description settings",
    landmarks: "Landmarks",
    neighbourhoods: "Neighbourhoods",
    newGround: "New Ground %",
  },
  groupDetails: {
    delete: "Delete",
  },
};
export const resources = {
  en: {
    translation: en,
  },
} as const;
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
