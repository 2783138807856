import {
  WhatsNewRequestBody,
  WhatsNewResponseType,
} from "./main/handlers/whatsnew/WhatsNewTypes";
import {
  UploadActivityRequestBody,
  UploadActivityResponseType,
} from "./main/handlers/uploadactivity/UploadActivityTypes";
import {
  GroupDeleteRequestBody,
  GroupDeleteResponseType,
} from "./main/handlers/groupdelete/GroupDeleteTypes";
import {
  AreasForPointRequestBody,
  AreasForPointResponseType,
} from "./main/handlers/areasforpoint/AreasForPointTypes";
import {
  ReviewPopupLogAttemptRequestBody,
  ReviewPopupLogAttemptResponseType,
} from "./main/handlers/reviewpopuplogattempt/ReviewPopupLogAttemptTypes";
import {
  ReviewPopupCanShowRequestBody,
  ReviewPopupCanShowResponseType,
} from "./main/handlers/reviewpopupcanshow/ReviewPopupCanShowTypes";
import {
  GetActivitiesWithinAreaRequestBody,
  GetActivitiesWithinAreaResponseType,
} from "./main/handlers/getactivitieswithinarea/GetActivitiesWithinAreaTypes";
import {
  DeleteActivityRequestBody,
  DeleteActivityResponseType,
} from "./main/handlers/deleteactivity/DeleteActivityTypes";
import {
  GroupRemoveMemberRequestBody,
  GroupRemoveMemberResponseType,
} from "./main/handlers/groupremovemember/GroupRemoveMemberTypes";
import {
  GroupRequestBody,
  GroupResponseType,
} from "./main/handlers/group/GroupTypes";
import {
  GroupsListRequestBody,
  GroupsListResponseType,
} from "./main/handlers/groupslist/GroupsListTypes";
import {
  GroupCreateRequestBody,
  GroupCreateResponseType,
} from "./main/handlers/groupcreate/GroupCreateTypes";
import {
  GroupGetMembersRequestBody,
  GroupGetMembersResponseType,
} from "./main/handlers/groupgetmembers/GroupGetMembersTypes";
import {
  GroupAddMemberRequestBody,
  GroupAddMemberResponseType,
} from "./main/handlers/groupaddmember/GroupAddMemberTypes";
import {
  HistoricUserCityPercentagesRequestBody,
  HistoricUserCityPercentagesResponseType,
} from "./main/handlers/historicusercitypercentages/HistoricUserCityPercentagesTypes";
import {
  ReportInaccessibleAreaRequestBody,
  ReportInaccessibleAreaResponseType,
} from "./main/handlers/reportinaccessiblearea/ReportInaccessibleAreaTypes";
import {
  DeleteAccountRequestBody,
  DeleteAccountResponseType,
} from "./main/handlers/deleteaccount/DeleteAccountTypes";
import {
  IosValidateReceiptRequestBody,
  IosValidateReceiptResponseType,
} from "./main/handlers/iosvalidatereceipt/IosValidateReceiptTypes";
import {
  SubscriptionGetAvailableProductsRequestBody,
  SubscriptionGetAvailableProductsResponseType,
} from "./main/handlers/subscriptiongetavailableproducts/SubscriptionGetAvailableProductsTypes";
import {
  SubscriptionGetRequestBody,
  SubscriptionGetResponseType,
} from "./main/handlers/subscriptionget/SubscriptionGetTypes";
import {
  SubscriptionGetPortalUrlRequestBody,
  SubscriptionGetPortalUrlResponseType,
} from "./main/handlers/subscriptiongetportalurl/SubscriptionGetPortalUrlTypes";
import {
  SubscriptionStoreRequestBody,
  SubscriptionStoreResponseType,
} from "./main/handlers/subscriptionstore/SubscriptionStoreTypes";
import {
  SubscriptionCreateCheckoutRequestBody,
  SubscriptionCreateCheckoutResponseType,
} from "./main/handlers/subscriptioncreatecheckout/SubscriptionCreateCheckoutTypes";
import {
  GetLeaderboardForUserRequestBody,
  GetLeaderboardForUserResponseType,
} from "./main/handlers/getleaderboardforuser/GetLeaderboardForUserTypes";
import {
  TopNeighbourhoodsRequestBody,
  TopNeighbourhoodsResponseType,
} from "./main/handlers/topneighbourhoods/TopNeighbourhoodsTypes";
import {
  GetLandmarksWithVisitsByNeighbourhoodRequestBody,
  GetLandmarksWithVisitsByNeighbourhoodResponseType,
} from "./main/handlers/getlandmarkswithvisitsbyneighbourhood/GetLandmarksWithVisitsByNeighbourhoodTypes";
import {
  GetUserNeighbourhoodRequestBody,
  GetUserNeighbourhoodResponseType,
} from "./main/handlers/getuserneighbourhood/GetUserNeighbourhoodTypes";
import {
  GetUserNeighbourhoodsByUserAreaRequestBody,
  GetUserNeighbourhoodsByUserAreaResponseType,
} from "./main/handlers/getuserneighbourhoodsbyuserarea/GetUserNeighbourhoodsByUserAreaTypes";
import {
  DeleteCustomAreaRequestBody,
  DeleteCustomAreaResponseType,
} from "./main/handlers/deletecustomarea/DeleteCustomAreaTypes";
import {
  AddCustomAreaRequestBody,
  AddCustomAreaResponseType,
} from "./main/handlers/addcustomarea/AddCustomAreaTypes";
import {
  GetAchievementByIdRequestBody,
  GetAchievementByIdResponseType,
} from "./main/handlers/getachievementbyid/GetAchievementByIdTypes";
import {
  GetAchievementsForUserByAreaRequestBody,
  GetAchievementsForUserByAreaResponseType,
} from "./main/handlers/getachievementsforuserbyarea/GetAchievementsForUserByAreaTypes";
import {
  SetSettingsRequestBody,
  SetSettingsResponseType,
} from "./main/handlers/setsettings/SetSettingsTypes";
import {
  GetSettingsRequestBody,
  GetSettingsResponseType,
} from "./main/handlers/getsettings/GetSettingsTypes";
import {
  EmailActionRequestBody,
  EmailActionResponseType,
} from "./main/handlers/emailaction/EmailActionTypes";
import {
  AddEventRequestBody,
  AddEventResponseType,
} from "./main/handlers/addevent/AddEventTypes";
import {
  SubmitFeedbackRequestBody,
  SubmitFeedbackResponseType,
} from "./main/handlers/submitfeedback/SubmitFeedbackTypes";
import {
  UserRequestBody,
  UserResponseType,
} from "./main/handlers/user/UserTypes";
import {
  UpdateUserRequestBody,
  UpdateUserResponseType,
} from "./main/handlers/updateuser/UpdateUserTypes";
import {
  GetLandmarkDetailsRequestBody,
  GetLandmarkDetailsResponseType,
} from "./main/handlers/getlandmarkdetails/GetLandmarkDetailsTypes";
import {
  GetLandmarksWithVisitsInAreaRequestBody,
  GetLandmarksWithVisitsInAreaResponseType,
} from "./main/handlers/getlandmarkswithvisitsinarea/GetLandmarksWithVisitsInAreaTypes";
import {
  GetLandmarksByAreaIdRequestBody,
  GetLandmarksByAreaIdResponseType,
} from "./main/handlers/getlandmarksbyareaid/GetLandmarksByAreaIdTypes";
import {
  GetMostRecentActivityDetailsRequestBody,
  GetMostRecentActivityDetailsResponseType,
} from "./main/handlers/getmostrecentactivitydetails/GetMostRecentActivityDetailsTypes";
import {
  ImportFromVendorRequestBody,
  ImportFromVendorResponseType,
} from "./main/handlers/importfromvendor/ImportFromVendorTypes";
import {
  GetCityByIdRequestBody,
  GetCityByIdResponseType,
} from "./main/handlers/getcitybyid/GetCityByIdTypes";
import {
  GetCitiesRequestBody,
  GetCitiesResponseType,
} from "./main/handlers/getcities/GetCitiesTypes";
/**
 * Created by matthewjamieson on 26/11/2020.
 */

import {
  AddVendorUserRequestType,
  AddVendorUserResponseType,
} from "./main/handlers/auth/addvendoruser/AddVendorUserTypes";
import {
  GetActivitiesRequestBody,
  GetActivitiesResponseType,
} from "./main/handlers/activities/getactivities/GetActivitiesTypes";
import { GetUserCitiesResponseType } from "./main/handlers/getusercities/GetUserCitiesTypes";
import { UpdateHeatmapResponseType } from "./admin/handlers/updateheatmap/UpdateHeatmapTypes";
import { ResourceObjectType, ResourceType } from "framework/src/types/Api";
import {
  ActivityPercentCoveredRequestBody,
  ActivityPercentCoveredResponseType,
} from "./main/handlers/activities/activitypercentagecovered/ActivityPercentCoveredTypes";
import {
  ActivityDetailsRequestBody,
  ActivityDetailsResponseType,
} from "./main/handlers/activities/activitydetails/ActivityDetailsTypes";
import {
  EmailOpenRequestBody,
  EmailOpenResponseData,
} from "./main/handlers/emailopen/EmailOpenTypes";
import { AreaType } from "./data/AreaType";
export type { GroupType } from "./main/datalayer/groups/GroupsData";
export { AccountTypes } from "./main/datalayer/user/UserTypes";
export type { LeaderboardRowType } from "./main/datalayer/leaderboard/LeaderboardData";
export { TimePeriods } from "./data/Periods";
/**
 * Export main data types
 */
export type { UserCityType } from "./main/datalayer/cities/UserCitiesTypes";
export type { ActivityType } from "./main/datalayer/activities/ActivityTypes";
export type { UserType } from "./main/datalayer/user/UserTypes";
export type {
  CityWithBB,
  CityWithBBSimple,
} from "./main/datalayer/cities/CityTypes";
export type { LandmarkType } from "./main/datalayer/landmarks/LandmarkTypes";
export type { AchievementType } from "./main/datalayer/achievements/AchievementType";
export type { VisitedLandmarkType } from "./main/datalayer/landmarks/LandmarkTypes";
export type {
  UserNeighbourhoodType,
  Neighbourhood,
  UserNeighbourhoodSimple,
  NeighbourhoodSimple,
} from "./main/datalayer/neighbourhoods/NeighbourhoodTypes";

export type {
  SetSettingsType,
  SettingsType,
} from "./main/datalayer/settings/SettingsTypes";
export { AreaType } from "./data/AreaType";
export type { ProductWithPricesType } from "./thirdparty/stripe/StripeService";
export { LandmarkTypeEnum } from "./main/datalayer/landmarks/LandmarkTypes";
export enum ApiRoutes {
  WhatsNew = "/whats_new",
  UploadActivity = "/upload_activity",
  GroupDelete = "/group_delete",
  AreasForPoint = "/areas_for_point",
  ReviewPopupLogAttempt = "/review_popup_log_attempt",
  ReviewPopupCanShow = "/review_popup_can_show",
  GetActivitiesWithinArea = "/get_activities_within_area",
  DeleteActivity = "/delete_activity",
  GroupRemoveMember = "/group_remove_member",
  Group = "/group",
  GroupsList = "/groups_list",
  GroupCreate = "/group_create",
  Groups = "/groups",
  GroupGetMembers = "/group_get_members",
  GroupAddMember = "/group_add_member",
  CreateGroup = "/create_group",
  HistoricUserCityPercentages = "/historic_user_city_percentages",
  ReportInaccessibleArea = "/report_inaccessible_area",
  DeleteAccount = "/delete_account",
  IosValidateReceipt = "/ios_validate_receipt",
  SubscriptionGetAvailableProducts = "/subscription_get_available_products",
  SubscriptionGet = "/subscription_get",
  SubscriptionGetPortalUrl = "/subscription_get_portal_url",
  SubscriptionStore = "/subscription_store",
  SubscriptionCreateCheckout = "/subscription_create_checkout",
  GetLeaderboardForUser = "/get_leaderboard_for_user",
  TopNeighbourhoods = "/top_neighbourhoods",
  GetLandmarksWithVisitsByNeighbourhood = "/get_landmarks_with_visits_by_neighbourhood",
  GetUserNeighbourhood = "/get_user_neighbourhood",
  GetUserNeighbourhoodsByUserArea = "/get_user_neighbourhoods_by_user_area",
  DeleteCustomArea = "/delete_custom_area",
  AddCustomArea = "/add_custom_area",
  GetAchievementById = "/get_achievement_by_id",
  GetAchievementsForUserByArea = "/get_achievements_for_user_by_area",
  SetSettings = "/set_settings",
  GetSettings = "/get_settings",
  EmailAction = "/email_action",
  EmailOpen = "/email_open",
  AddEvent = "/add_event",
  SubmitFeedbackPublic = "/submit_feedback_public",
  SubmitFeedback = "/submit_feedback",
  User = "/user",
  UpdateUser = "/update_user",
  GetLandmarkDetails = "/get_landmark_details",
  GetLandmarksWithVisitsInArea = "/get_landmarks_with_visits_in_area",
  GetLandmarksByAreaId = "/get_landmarks_by_area_id",
  GetMostRecentActivityDetails = "/get_most_recent_activity_details",
  ImportFromVendor = "/import_from_vendor",
  GetCityById = "/get_city_by_id",
  GetCities = "/get_cities",
  Login = "/login",
  Register = "/register",
  AddUser = "/users/add",
  GetActivities = "/get_activities",
  UpdateHeatmap = "/map/update_heatmap",
  GetUserCities = "/user/get_user_cities",
  UpdateActivityThumbs = "/update_activity_thumbs",
  GetActivityPercentCovered = "/get_activity_percent_covered",
  ActivityDetails = "/activity/details",

  //Public
  PublicGetCities = "/public_get_cities",
}

export type ApiResources = {
  [key in keyof ApiResponses]: ResourceType<
    key,
    ApiResponses[key],
    ApiRequestBodies[key]
  >;
};

export type ApiRequestBodies = {
  [ApiRoutes.WhatsNew]: WhatsNewRequestBody;
  [ApiRoutes.UploadActivity]: UploadActivityRequestBody;
  [ApiRoutes.GroupDelete]: GroupDeleteRequestBody;
  [ApiRoutes.AreasForPoint]: AreasForPointRequestBody;
  [ApiRoutes.ReviewPopupLogAttempt]: ReviewPopupLogAttemptRequestBody;
  [ApiRoutes.ReviewPopupCanShow]: ReviewPopupCanShowRequestBody;
  [ApiRoutes.GetActivitiesWithinArea]: GetActivitiesWithinAreaRequestBody;
  [ApiRoutes.DeleteActivity]: DeleteActivityRequestBody;
  [ApiRoutes.GroupRemoveMember]: GroupRemoveMemberRequestBody;
  [ApiRoutes.Group]: GroupRequestBody;
  [ApiRoutes.GroupsList]: GroupsListRequestBody;
  [ApiRoutes.GroupCreate]: GroupCreateRequestBody;
  [ApiRoutes.GroupGetMembers]: GroupGetMembersRequestBody;
  [ApiRoutes.GroupAddMember]: GroupAddMemberRequestBody;
  [ApiRoutes.HistoricUserCityPercentages]: HistoricUserCityPercentagesRequestBody;
  [ApiRoutes.ReportInaccessibleArea]: ReportInaccessibleAreaRequestBody;
  [ApiRoutes.DeleteAccount]: DeleteAccountRequestBody;
  [ApiRoutes.IosValidateReceipt]: IosValidateReceiptRequestBody;
  [ApiRoutes.SubscriptionGetAvailableProducts]: SubscriptionGetAvailableProductsRequestBody;
  [ApiRoutes.SubscriptionGet]: SubscriptionGetRequestBody;
  [ApiRoutes.SubscriptionGetPortalUrl]: SubscriptionGetPortalUrlRequestBody;
  [ApiRoutes.SubscriptionStore]: SubscriptionStoreRequestBody;
  [ApiRoutes.SubscriptionCreateCheckout]: SubscriptionCreateCheckoutRequestBody;
  [ApiRoutes.GetLeaderboardForUser]: GetLeaderboardForUserRequestBody;
  [ApiRoutes.TopNeighbourhoods]: TopNeighbourhoodsRequestBody;
  [ApiRoutes.GetLandmarksWithVisitsByNeighbourhood]: GetLandmarksWithVisitsByNeighbourhoodRequestBody;
  [ApiRoutes.GetUserNeighbourhood]: GetUserNeighbourhoodRequestBody;
  [ApiRoutes.GetUserNeighbourhoodsByUserArea]: GetUserNeighbourhoodsByUserAreaRequestBody;
  [ApiRoutes.DeleteCustomArea]: DeleteCustomAreaRequestBody;
  [ApiRoutes.AddCustomArea]: AddCustomAreaRequestBody;
  [ApiRoutes.GetAchievementById]: GetAchievementByIdRequestBody;
  [ApiRoutes.GetAchievementsForUserByArea]: GetAchievementsForUserByAreaRequestBody;
  [ApiRoutes.EmailAction]: EmailActionRequestBody;
  [ApiRoutes.SetSettings]: SetSettingsRequestBody;
  [ApiRoutes.GetSettings]: GetSettingsRequestBody;
  [ApiRoutes.EmailOpen]: EmailOpenRequestBody;
  [ApiRoutes.AddEvent]: AddEventRequestBody;
  [ApiRoutes.SubmitFeedbackPublic]: SubmitFeedbackRequestBody;
  [ApiRoutes.SubmitFeedback]: SubmitFeedbackRequestBody;
  [ApiRoutes.User]: UserRequestBody;
  [ApiRoutes.UpdateUser]: UpdateUserRequestBody;
  [ApiRoutes.GetLandmarkDetails]: GetLandmarkDetailsRequestBody;
  [ApiRoutes.GetLandmarksWithVisitsInArea]: GetLandmarksWithVisitsInAreaRequestBody;
  [ApiRoutes.GetLandmarksByAreaId]: GetLandmarksByAreaIdRequestBody;
  [ApiRoutes.GetMostRecentActivityDetails]: GetMostRecentActivityDetailsRequestBody;
  [ApiRoutes.ImportFromVendor]: ImportFromVendorRequestBody;
  [ApiRoutes.GetCityById]: GetCityByIdRequestBody;
  [ApiRoutes.GetCities]: GetCitiesRequestBody;
  [ApiRoutes.AddUser]: AddVendorUserRequestType;
  [ApiRoutes.GetActivities]: GetActivitiesRequestBody;
  [ApiRoutes.GetActivityPercentCovered]: ActivityPercentCoveredRequestBody;
  [ApiRoutes.ActivityDetails]: ActivityDetailsRequestBody;
  [key: string]: any;

  // Public
  [ApiRoutes.PublicGetCities]: GetCitiesRequestBody;
};

export type ApiResponses = {
  [ApiRoutes.WhatsNew]: WhatsNewResponseType;
  [ApiRoutes.UploadActivity]: UploadActivityResponseType;
  [ApiRoutes.GroupDelete]: GroupDeleteResponseType;
  [ApiRoutes.AreasForPoint]: AreasForPointResponseType;
  [ApiRoutes.ReviewPopupLogAttempt]: ReviewPopupLogAttemptResponseType;
  [ApiRoutes.ReviewPopupCanShow]: ReviewPopupCanShowResponseType;
  [ApiRoutes.GetActivitiesWithinArea]: GetActivitiesWithinAreaResponseType;
  [ApiRoutes.DeleteActivity]: DeleteActivityResponseType;
  [ApiRoutes.GroupRemoveMember]: GroupRemoveMemberResponseType;
  [ApiRoutes.Group]: GroupResponseType;
  [ApiRoutes.GroupsList]: GroupsListResponseType;
  [ApiRoutes.GroupCreate]: GroupCreateResponseType;
  [ApiRoutes.GroupGetMembers]: GroupGetMembersResponseType;
  [ApiRoutes.GroupAddMember]: GroupAddMemberResponseType;
  [ApiRoutes.HistoricUserCityPercentages]: HistoricUserCityPercentagesResponseType;
  [ApiRoutes.ReportInaccessibleArea]: ReportInaccessibleAreaResponseType;
  [ApiRoutes.DeleteAccount]: DeleteAccountResponseType;
  [ApiRoutes.IosValidateReceipt]: IosValidateReceiptResponseType;
  [ApiRoutes.SubscriptionGetAvailableProducts]: SubscriptionGetAvailableProductsResponseType;
  [ApiRoutes.SubscriptionGet]: SubscriptionGetResponseType;
  [ApiRoutes.SubscriptionGetPortalUrl]: SubscriptionGetPortalUrlResponseType;
  [ApiRoutes.SubscriptionStore]: SubscriptionStoreResponseType;
  [ApiRoutes.SubscriptionCreateCheckout]: SubscriptionCreateCheckoutResponseType;
  [ApiRoutes.GetLeaderboardForUser]: GetLeaderboardForUserResponseType;
  [ApiRoutes.TopNeighbourhoods]: TopNeighbourhoodsResponseType;
  [ApiRoutes.GetLandmarksWithVisitsByNeighbourhood]: GetLandmarksWithVisitsByNeighbourhoodResponseType;
  [ApiRoutes.GetUserNeighbourhood]: GetUserNeighbourhoodResponseType;
  [ApiRoutes.GetUserNeighbourhoodsByUserArea]: GetUserNeighbourhoodsByUserAreaResponseType;
  [ApiRoutes.DeleteCustomArea]: DeleteCustomAreaResponseType;
  [ApiRoutes.AddCustomArea]: AddCustomAreaResponseType;
  [ApiRoutes.GetAchievementById]: GetAchievementByIdResponseType;
  [ApiRoutes.GetAchievementsForUserByArea]: GetAchievementsForUserByAreaResponseType;
  [ApiRoutes.SetSettings]: SetSettingsResponseType;
  [ApiRoutes.GetSettings]: GetSettingsResponseType;
  [ApiRoutes.EmailAction]: EmailActionResponseType;
  [ApiRoutes.EmailOpen]: EmailOpenResponseData;
  [ApiRoutes.AddEvent]: AddEventResponseType;
  [ApiRoutes.SubmitFeedbackPublic]: SubmitFeedbackResponseType;
  [ApiRoutes.SubmitFeedback]: SubmitFeedbackResponseType;
  [ApiRoutes.User]: UserResponseType;
  [ApiRoutes.UpdateUser]: UpdateUserResponseType;
  [ApiRoutes.GetLandmarkDetails]: GetLandmarkDetailsResponseType;
  [ApiRoutes.GetLandmarksWithVisitsInArea]: GetLandmarksWithVisitsInAreaResponseType;
  [ApiRoutes.GetLandmarksByAreaId]: GetLandmarksByAreaIdResponseType;
  [ApiRoutes.GetMostRecentActivityDetails]: GetMostRecentActivityDetailsResponseType;
  [ApiRoutes.ImportFromVendor]: ImportFromVendorResponseType;
  [ApiRoutes.GetCityById]: GetCityByIdResponseType;
  [ApiRoutes.GetCities]: GetCitiesResponseType;
  [ApiRoutes.Login]: any;
  [ApiRoutes.Register]: any;
  [ApiRoutes.UpdateActivityThumbs]: any; //todo;
  [ApiRoutes.AddUser]: AddVendorUserResponseType;
  [ApiRoutes.GetActivities]: GetActivitiesResponseType;
  [ApiRoutes.GetUserCities]: GetUserCitiesResponseType;
  [ApiRoutes.UpdateHeatmap]: UpdateHeatmapResponseType;
  [ApiRoutes.GetActivityPercentCovered]: ActivityPercentCoveredResponseType;
  [ApiRoutes.ActivityDetails]: ActivityDetailsResponseType;
  // public
  [ApiRoutes.PublicGetCities]: GetCitiesResponseType;
};

export type CPResourceObjectType = ResourceObjectType<ApiRoutes>;

export const ApiResourceObjects: {
  [key in ApiRoutes]: CPResourceObjectType;
} = {
  [ApiRoutes.WhatsNew]: createResource(ApiRoutes.WhatsNew, "POST"),

  [ApiRoutes.UploadActivity]: createResource(ApiRoutes.UploadActivity, "POST"),

  [ApiRoutes.GroupDelete]: createResource(ApiRoutes.GroupDelete, "POST"),

  [ApiRoutes.AreasForPoint]: createResource(ApiRoutes.AreasForPoint, "POST"),

  [ApiRoutes.ReviewPopupLogAttempt]: createResource(
    ApiRoutes.ReviewPopupLogAttempt,
    "POST"
  ),

  [ApiRoutes.ReviewPopupCanShow]: createResource(
    ApiRoutes.ReviewPopupCanShow,
    "POST"
  ),

  [ApiRoutes.GetActivitiesWithinArea]: createResource(
    ApiRoutes.GetActivitiesWithinArea,
    "POST"
  ),

  [ApiRoutes.DeleteActivity]: createResource(ApiRoutes.DeleteActivity, "POST"),

  [ApiRoutes.GroupRemoveMember]: createResource(
    ApiRoutes.GroupRemoveMember,
    "POST"
  ),

  [ApiRoutes.Group]: createResource(ApiRoutes.Group, "POST"),

  [ApiRoutes.GroupsList]: createResource(ApiRoutes.GroupsList, "POST"),

  [ApiRoutes.GroupCreate]: createResource(ApiRoutes.GroupCreate, "POST"),

  [ApiRoutes.Groups]: createResource(ApiRoutes.Groups, "POST"),

  [ApiRoutes.GroupGetMembers]: createResource(
    ApiRoutes.GroupGetMembers,
    "POST"
  ),

  [ApiRoutes.GroupAddMember]: createResource(ApiRoutes.GroupAddMember, "POST"),

  [ApiRoutes.CreateGroup]: createResource(ApiRoutes.CreateGroup, "POST"),

  [ApiRoutes.HistoricUserCityPercentages]: createResource(
    ApiRoutes.HistoricUserCityPercentages,
    "POST"
  ),

  [ApiRoutes.ReportInaccessibleArea]: createResource(
    ApiRoutes.ReportInaccessibleArea,
    "POST"
  ),

  [ApiRoutes.DeleteAccount]: createResource(ApiRoutes.DeleteAccount, "POST"),

  [ApiRoutes.IosValidateReceipt]: createResource(
    ApiRoutes.IosValidateReceipt,
    "POST"
  ),

  [ApiRoutes.SubscriptionGetAvailableProducts]: createResource(
    ApiRoutes.SubscriptionGetAvailableProducts,
    "POST"
  ),

  [ApiRoutes.SubscriptionGet]: createResource(
    ApiRoutes.SubscriptionGet,
    "POST"
  ),

  [ApiRoutes.SubscriptionGetPortalUrl]: createResource(
    ApiRoutes.SubscriptionGetPortalUrl,
    "POST"
  ),

  [ApiRoutes.SubscriptionStore]: createResource(
    ApiRoutes.SubscriptionStore,
    "POST"
  ),

  [ApiRoutes.SubscriptionCreateCheckout]: createResource(
    ApiRoutes.SubscriptionCreateCheckout,
    "POST"
  ),

  [ApiRoutes.GetLeaderboardForUser]: createResource(
    ApiRoutes.GetLeaderboardForUser,
    "POST"
  ),

  [ApiRoutes.TopNeighbourhoods]: createResource(
    ApiRoutes.TopNeighbourhoods,
    "POST"
  ),

  [ApiRoutes.GetLandmarksWithVisitsByNeighbourhood]: createResource(
    ApiRoutes.GetLandmarksWithVisitsByNeighbourhood,
    "POST"
  ),

  [ApiRoutes.GetUserNeighbourhood]: createResource(
    ApiRoutes.GetUserNeighbourhood,
    "POST"
  ),

  [ApiRoutes.GetUserNeighbourhoodsByUserArea]: createResource(
    ApiRoutes.GetUserNeighbourhoodsByUserArea,
    "POST"
  ),

  [ApiRoutes.DeleteCustomArea]: createResource(
    ApiRoutes.DeleteCustomArea,
    "POST"
  ),

  [ApiRoutes.AddCustomArea]: createResource(ApiRoutes.AddCustomArea, "POST"),

  [ApiRoutes.GetAchievementById]: createResource(
    ApiRoutes.GetAchievementById,
    "POST"
  ),

  [ApiRoutes.GetAchievementsForUserByArea]: createResource(
    ApiRoutes.GetAchievementsForUserByArea,
    "POST"
  ),

  [ApiRoutes.SetSettings]: createResource(ApiRoutes.SetSettings, "POST"),

  [ApiRoutes.GetSettings]: createResource(ApiRoutes.GetSettings, "POST"),

  [ApiRoutes.EmailAction]: createResource(ApiRoutes.EmailAction, "POST"),

  [ApiRoutes.EmailOpen]: createResource(ApiRoutes.EmailOpen, "GET"),
  [ApiRoutes.AddEvent]: createResource(ApiRoutes.AddEvent, "POST"),

  [ApiRoutes.SubmitFeedbackPublic]: createResource(
    ApiRoutes.SubmitFeedbackPublic,
    "POST"
  ),

  [ApiRoutes.SubmitFeedback]: createResource(ApiRoutes.SubmitFeedback, "POST"),

  [ApiRoutes.User]: createResource(ApiRoutes.User, "POST"),

  [ApiRoutes.UpdateUser]: createResource(ApiRoutes.UpdateUser, "POST"),

  [ApiRoutes.GetLandmarkDetails]: createResource(
    ApiRoutes.GetLandmarkDetails,
    "POST"
  ),

  [ApiRoutes.GetLandmarksWithVisitsInArea]: createResource(
    ApiRoutes.GetLandmarksWithVisitsInArea,
    "POST"
  ),

  [ApiRoutes.GetLandmarksByAreaId]: createResource(
    ApiRoutes.GetLandmarksByAreaId,
    "POST"
  ),

  [ApiRoutes.GetMostRecentActivityDetails]: createResource(
    ApiRoutes.GetMostRecentActivityDetails,
    "POST"
  ),

  [ApiRoutes.ImportFromVendor]: createResource(
    ApiRoutes.ImportFromVendor,
    "POST"
  ),

  [ApiRoutes.GetCityById]: createResource(ApiRoutes.GetCityById, "POST"),

  [ApiRoutes.GetCities]: createResource(ApiRoutes.GetCities, "POST"),

  [ApiRoutes.Login]: createResource(ApiRoutes.Login, "POST"),
  [ApiRoutes.Register]: createResource(ApiRoutes.Register, "POST"),
  [ApiRoutes.UpdateActivityThumbs]: createResource(
    ApiRoutes.UpdateActivityThumbs,
    "POST"
  ), //todo;
  [ApiRoutes.AddUser]: createResource(ApiRoutes.AddUser, "POST"),
  [ApiRoutes.GetActivities]: createResource(ApiRoutes.GetActivities, "POST"),
  [ApiRoutes.GetUserCities]: createResource(ApiRoutes.GetUserCities, "GET"),
  [ApiRoutes.UpdateHeatmap]: createResource(ApiRoutes.GetUserCities, "GET"),
  [ApiRoutes.GetActivityPercentCovered]: createResource(
    ApiRoutes.GetActivityPercentCovered,
    "POST"
  ),
  [ApiRoutes.ActivityDetails]: createResource(
    ApiRoutes.ActivityDetails,
    "POST"
  ),
  // Public
  [ApiRoutes.PublicGetCities]: createResource(
    ApiRoutes.PublicGetCities,
    "POST"
  ),
};

function createResource(
  path: ApiRoutes,
  method: "POST" | "GET"
): CPResourceObjectType {
  return {
    path,
    getPath() {
      return this.path;
    },
    method,
  };
}
