import React, { useEffect } from "react";
import styles from "./DashboardHeader.module.css";
import { Link } from "react-router-dom";
import Select from "../../../components/select/Select";
import sharedStyles from "../../../../styles/shared.module.css";
import { UserCityType } from "cp-server";
import { useTranslation } from "react-i18next";
import { home } from "ionicons/icons";

import {
  Button,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import { ReactComponent as MoreVert } from "../../../../assets/more-vert-black.svg";
import classNames from "classnames";
import {
  useGetSettingsQuery,
  useSetSettingsMutation,
} from "../../../../state/api/ApiSlice";
import {
  hidePopup,
  PopupTypes,
  showPopup,
} from "../../../../state/popups/PopupsActions";
import { useDispatch } from "react-redux";
import { getPercent } from "../../../../data/utils/PercentageUtils";
import AreasProcessingNote from "../../../components/areas-processing-note/AreasProcessingNote";
import DisplayIf from "../../../../components/display-if/DisplayIf";
import { IonAlert, IonButton, IonIcon } from "@ionic/react";

type PropsType = {
  userCities: Array<UserCityType>;
  onChangeCity: (id: string) => void;
  userArea: UserCityType;
};

export default function DashboardHeader(props: PropsType) {
  const { data: settings } = useGetSettingsQuery();
  const dispatch = useDispatch();
  const [setSettings] = useSetSettingsMutation();
  const hasHome = settings?.success && settings?.settings?.home_area;
  const [isHomeAreaOpen, setIsHomeAreaOpen] = React.useState(false);
  const isHome =
    hasHome && settings?.settings?.home_area === props.userArea.area_id;
  function setHome(area_id: string | null) {
    setSettings({
      settings: { ...settings, home_area: area_id },
    });
  }
  function renderDropdown() {
    const userCities = props.userCities;

    const buttons = [...userCities]
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((uc) => ({
        id: uc.user_city_id,
        label: `${uc.name} - ${getPercent(uc.percent_covered)}%`,
      }));
    return (
      <Select
        value={props.userArea?.user_city_id}
        onChange={(id) => {
          props.onChangeCity(id);
        }}
        buttons={buttons}
      />
    );
  }

  return (
    <>
      <div className={styles.dashboardHeader}>
        {renderDropdown()}
        <IonButton
          onClick={() => {
            if (!isHome) {
              setIsHomeAreaOpen(true);
            } else {
              // Remove as home area if it is already the home area
              // TODO this does not work because null will not override existing values in the database query. see setUserSettings.sql
              setHome(null);
            }
          }}
          size={"small"}
          fill={isHome ? "solid" : "outline"}
        >
          <IonIcon icon={home} slot={"icon-only"} size={"small"} />
        </IonButton>
        <IonAlert
          isOpen={isHomeAreaOpen}
          subHeader={`Set ${props.userArea.name} as your home area?`}
          message={`This means that your dashboard will always show ${props.userArea.name} first`}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                setIsHomeAreaOpen(false);
              },
            },
            {
              text: "OK",
              role: "confirm",
              handler: () => {
                setHome(props.userArea.area_id);

                setIsHomeAreaOpen(false);
              },
            },
          ]}
          onDidDismiss={() => setIsHomeAreaOpen(false)}
        ></IonAlert>

        <DashboardMenu userArea={props.userArea} />
      </div>
      <AreasProcessingNote />
    </>
  );
}

function DashboardMenu({ userArea }: { userArea: UserCityType }) {
  const { t } = useTranslation();
  const { data: settings } = useGetSettingsQuery();
  const dispatch = useDispatch();
  const [showLandmarks, setShowLandmarks] = React.useState(false);

  const [setSettings] = useSetSettingsMutation();

  console.log(settings);
  const saveSettings = (showLandmarks: boolean) => {
    setSettings({
      settings: { ...settings, map_show_landmarks: showLandmarks },
    });
  };

  // Set initial landmark setting
  useEffect(() => {
    if (settings?.success) {
      setShowLandmarks(settings.settings.map_show_landmarks);
    }
  }, [settings]);

  const showFeedback = () => {
    setOpen(false);
    dispatch(showPopup({ popupType: PopupTypes.FEEDBACK }));
  };

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        open={open}
        onClose={handleClick}
        anchorEl={anchorEl}
      >
        <MenuItem onClick={() => setOpen(false)}>
          <Link
            to={`/tabs/home/whats-new`}
            className={classNames(sharedStyles.text, styles.menuItem)}
          >
            {t("dashboard.header.whatsNew")}
          </Link>
        </MenuItem>
        <MenuItem className={classNames(sharedStyles.text, styles.menuItem)}>
          <FormControlLabel
            classes={{
              label: classNames(sharedStyles.text, styles.menuItem),
            }}
            control={
              <Switch
                checked={showLandmarks}
                onChange={(event, checked) => {
                  setShowLandmarks(checked);
                  setTimeout(() => {
                    // slight Delay to make the UX feel nicer - So the toggle can move before the menu closes
                    setOpen(false);
                  }, 200);
                  saveSettings(checked);
                }}
                name="show_landmarks"
                color="primary"
              />
            }
            label={t("dashboard.header.showLandmarks")}
          />
        </MenuItem>
        <hr />
        <MenuItem onClick={() => setOpen(false)}>
          <Link
            to={`/tabs/home/report-map/${userArea.area_id}`}
            className={classNames(sharedStyles.text, styles.menuItem)}
          >
            {t("dashboard.header.report")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={showFeedback}
          className={classNames(sharedStyles.text, styles.menuItem)}
        >
          {t("dashboard.header.contact")}
        </MenuItem>
      </Menu>
    </div>
  );
}
