import React from "react";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { IonButton, IonContent } from "@ionic/react";
import { useWhatsNewQuery } from "../../state/api/ApiSlice";
import { getResults, getResultsFromResponse } from "../../data/ApiData";
import WaitForData from "../../components/WaitForData";
import { Skeleton } from "@mui/material";
import Content from "../../components/content/Content";
import styles from "./WhatsNew.module.css";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import DisplayIf from "../../components/display-if/DisplayIf";
import moment from "moment/moment";

export default function WhatsNew() {
  const {
    data: whatsNewResponse,
    isLoading: isWhatsNewLoading,
  } = useWhatsNewQuery({});
  const whatsNew = getResultsFromResponse(whatsNewResponse);

  const renderContent = () => {
    return (
      <div className={styles.list}>
        {whatsNew.map((whatsNewItem) => {
          return (
            <div className={styles.card} key={whatsNewItem.whats_new_id}>
              <div className={styles.header}>
                <div
                  className={classNames(sharedStyles.heading3, styles.title)}
                >
                  {whatsNewItem.title}
                </div>
                {moment(whatsNewItem.date).format("MMMM Do YYYY")}
              </div>
              <div className={classNames(sharedStyles.text)}>
                {whatsNewItem.description}
              </div>
              <DisplayIf condition={Boolean(whatsNewItem.image_url)}>
                <div className={styles.imageContainer}>
                  <img
                    src={whatsNewItem.image_url}
                    alt={whatsNewItem.title}
                    className={styles.image}
                  />
                </div>
              </DisplayIf>
              <DisplayIf condition={Boolean(whatsNewItem.url)}>
                <Link to={whatsNewItem.url}>
                  <IonButton expand={"full"}>View</IonButton>
                </Link>
              </DisplayIf>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>City Painter - Activity Details</title>
      </Helmet>
      <Header title={"Whats new"} />
      <IonContent>
        <Content>
          <WaitForData
            isLoading={isWhatsNewLoading}
            loadingElement={
              <div>
                <Skeleton variant="rectangular" height={"50vh"} />
              </div>
            }
          >
            {renderContent()}
          </WaitForData>
        </Content>
      </IonContent>
    </>
  );
}
