/* eslint-disable */
import React, { PureComponent } from "react";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import bbox from "@turf/bbox";
import scale from "@turf/transform-scale";
import { LandmarkType } from "cp-server";
import styles from "./CPMap.module.css";
import maplibregl, {
  LayerSpecification,
  StyleSpecification,
} from "maplibre-gl";

import ImageManager from "../../data/images/ImageManager";
import ImageKeys from "../../data/images/ImageKeys";
import { Polygon } from "geojson";
import { getLandmarkType } from "../../data/utils/formating/LandmarkFormatting";
import {
  getMainMapSources,
  MapStyleParams,
  MapStyles,
  mapStyles,
} from "./MapStyles";
import { mapImages } from "./MapImages";

import { isIos } from "../../platform/PlatformManager";

import "maplibre-gl/dist/maplibre-gl.css";
import bboxPolygon from "@turf/bbox-polygon";
import { WaysType } from "cp-types";
import { square } from "@turf/turf";

type DispatchProps = {};
type StateProps = {};
type OtherProps = {
  unfilled_map?: boolean; // displayed the roads/trails in a transparent color to give the impression of being 'Unpainted'
  simple?: boolean; // shows a 'simple' map where it is easy for a user to spot gaps in the heatmap
  mapStyle?: string;
  area_id: string;
  boundingBox: Polygon | undefined;
  landmarks: Array<LandmarkType>;
  bigLandmarks?: boolean; // force landmarks to always show full size
  className: string;
  layers?: Array<LayerSpecification>;
  sources?: any;
  initialBounds?: Polygon;
  center?: Array<any> | undefined; // TODO what is this type
  zoom?: number;
  onBoundsChange?: (bounds: number[], zoom: number) => void;
  // This is only called for live maps. TODO. Make less confusing
  onSetup?: (map: maplibregl.Map, bounds: number[], zoom: number) => void;
  waysType?: WaysType;
};
type PropsType = DispatchProps & StateProps & OtherProps;

type StateType = { fullscreen: boolean };

async function loadImages(map, urls, callback) {
  //TODO callback and wait might not be needed anymore. This function is no longer awaited
  await Promise.all(
    Object.keys(urls).map(
      (name) =>
        new Promise<void>(async (resolve) => {
          const img = await map.loadImage(urls[name]);
          map.addImage(name, img.data);
          resolve();
        })
    )
  );
  callback();
}
export default class CPMap extends PureComponent<PropsType, StateType> {
  mapContainer: any;
  map: maplibregl.Map;
  constructor(props) {
    super(props);
    this.mapContainer = React.createRef();
    this.state = {
      fullscreen: false,
    };
  }

  componentDidMount() {
    this.setupMap();
  }

  async setupMap() {
    const boundingBox = this.props.boundingBox as Polygon;
    const unfilledMap = this.props.unfilled_map;
    const simple = this.props.simple || false;
    let params;
    if (this.props.center && this.props.zoom) {
      params = {
        center: this.props.center,
        zoom: this.props.zoom,
      };
    } else {
      const initialBounds = bbox(
        // @ts-ignore - TODO Fix types here
        scale(this.props.initialBounds || boundingBox, 1.05, {
          origin: "center",
        })
      );
      params = { bounds: initialBounds };
    }
    // @ts-ignore - TODO Fix types here
    const maxBB = square(bbox(scale(boundingBox, 4, { origin: "center" })));
    const maxBounds = [
      [maxBB[0], maxBB[1]],
      [maxBB[2], maxBB[3]],
    ];

    const sources = await getMainMapSources(
      this.props.area_id,
      this.props.sources
    );

    const mapStyleParams: MapStyleParams = {
      unfilled: unfilledMap,
      simple,
      ways: this.props.waysType || WaysType.all,
    };
    const layers = mapStyles[this.props.mapStyle || "default"](
      this.props.layers,
      mapStyleParams
    );

    const style: StyleSpecification = {
      version: 8,
      glyphs: "https://citypainter.io/fonts/{fontstack}/{range}.pbf",
      sources,
      layers,
    };

    let map: maplibregl.Map;
    if (this.mapContainer.current) {
      map = this.map = new maplibregl.Map({
        container: this.mapContainer.current,
        style,
        maxBounds: maxBounds,
        maxZoom: 15,
        minZoom: 8,
        antialias: true,
        maxParallelImageRequests: 64,
        attributionControl: false,
        ...params,
      });

      // const locationControl = map.addControl(
      //   new maplibregl.GeolocateControl({
      //     positionOptions: {
      //       enableHighAccuracy: true,
      //     },
      //     trackUserLocation: true,
      //   })
      // );
      const libreBounds = map.getBounds().toArray();
      // @ts-ignore
      const bBoxPolygon = bboxPolygon(libreBounds.flat()).geometry;
      const coords = bBoxPolygon.coordinates[0].slice(0, 4);

      // On setup only gets called for live maps. TODO maybe make this less confusing
      // [MapStyles.LiveMap, MapStyles.reporting_tool].includes(this.props.mapStyle) &&
      this.props.onSetup &&
        this.props.onSetup(map, bbox(bBoxPolygon), map.getZoom());

      // locationControl.on('trackuserlocationstart', () => {
      //   console.log('A trackuserlocationstart event has occurred.')
      // });
    } else {
      // If the map container is not ready, return null
      // THIS SHOULDN'T HAPPEN
      console.error("ERROR: NO MAP CONTAINER");
      return null;
    }

    /**
     * Uncomment this for zoom snapping
     * Potentially delete later as this code might not be needed again
     * Start zoom snap handling
     */

    /*
    map.scrollZoom.disable();
    let mouseCoords;

    map.on("mousemove", function (e) {
      mouseCoords = e.lngLat;
    });

    const debouncedHandler = debounce((event, around) => {
      const wheelDelta = event.originalEvent.wheelDelta;
      map.easeTo({
        zoom: Math.round(map.getZoom()) + Math.sign(wheelDelta),
        around,
        duration: 200,
      });
    }, 50);
    map.on("wheel", (event) => {
      event.originalEvent.preventDefault();
      debouncedHandler(event, mouseCoords);
    });
    */

    loadImages(map, mapImages, () => {});
    map.on("touchend", () => {
      const libreBounds = map.getBounds().toArray();
      // @ts-ignore
      const bBoxPolygon = bboxPolygon(libreBounds.flat()).geometry;
      const coords = bBoxPolygon.coordinates[0].slice(0, 4);

      this.props.onBoundsChange &&
        this.props.onBoundsChange(bbox(bBoxPolygon), map.getZoom());

      //@ts-ignore
      map?.getSource("canvas-source")?.setCoordinates(coords.reverse());
    });

    map.on("load", () => {
      // Dont show landmarks on 'Simple' map
      !simple && this.setupLandmarks(map);
      // map.setZoom(Math.round(map.getZoom()));

      //TODO This feels messy. Should there be some other way of communicating boundaries?
      const libreBounds = map.getBounds().toArray();
      // @ts-ignore
      const bBoxPolygon = bboxPolygon(libreBounds.flat()).geometry;
      const coords = bBoxPolygon.coordinates[0].slice(0, 4);
      //this.props.onSetup && this.props.onSetup(map,bbox(bBoxPolygon), map.getZoom() );

      this.props.onBoundsChange &&
        this.props.onBoundsChange(bbox(bBoxPolygon), map.getZoom());
      //@ts-ignore
      map?.getSource("canvas-source")?.setCoordinates(coords.reverse());
    });
    this.setupNeighbourhoods(map);
  }

  setupNeighbourhoods(map) {
    map.on("click", "neighbourhoods", handleNeighbourhoodClick);
    function handleNeighbourhoodClick(e) {
      const id = e.features[0].properties.id;
      const name = e.features[0].properties.name;
      const percent = (e.features[0].properties.percentComplete * 100).toFixed(
        1
      );
      var coordinates = e.lngLat;
      new maplibregl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          `<div style="margin-right: 20px;text-transform: capitalize;">
                    <a href="/tabs/home/neighbourhood-details/${id}" style="color:var(--orange-1);text-decoration: none"><strong>${name}</strong></a>
                    <br/><span style="color:var(--text-medium);text-decoration: none">${percent}%</span>
          </a>
                 </div>`
        )
        .addTo(map);
    }
  }

  setupLandmarks(map) {
    const big = this.props.bigLandmarks;
    const landmarks = this.props.landmarks;
    const unfilledMap = this.props.unfilled_map;
    const landscapeGeo = landmarks.map((lm) => {
      const visits = lm.visits ? parseInt(lm.visits) : 0;
      return {
        type: "Feature",
        geometry: lm.way_point,
        properties: {
          image: visits ? lm.type + "_visited" : lm.type,
          name: lm.name,
          type: lm.type,
          visits: visits,
          landmark_id: lm.landmark_id,
        },
      };
    });

    console.log("loaded images");

    map.addSource("point", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: landscapeGeo,
      },
    });
    const zoomValues = big ? [9, 0.35, 10, 0.35, 11, 0.5] : [12, 0.35, 13, 0.5];
    map.addLayer({
      id: "landmarks",
      type: "symbol",
      source: "point",
      layout: {
        "icon-image": ["get", "image"],
        "icon-size": ["interpolate", ["linear"], ["zoom"], ...zoomValues],
        "icon-allow-overlap": true,
      },
      minzoom: big ? 9 : 12,
    });
    // Only need small landmark circles when the bigger ones aren't displayed
    !big &&
      map.addLayer({
        id: "landmarks_dots",
        type: "circle",
        source: "point",
        paint: {
          "circle-radius": 2,
          "circle-color": [
            "case",
            ["==", ["get", "visits"], 0],
            "#ffffff",
            "#fd7f00",
          ],
          "circle-stroke-width": 1,
          "circle-stroke-color": "#2c353d",
        },
        maxzoom: 12,
        minzoom: 9,
      });
    const handleLandmarkClick = (e) => {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var name = e.features[0].properties.name;
      const id = e.features[0].properties.landmark_id;
      var type = getLandmarkType(e.features[0].properties.type);
      var visits = e.features[0].properties.visits;
      const showVisits = unfilledMap; // This is because 'unfilled' maps are on pages that display the users heat map and therefore the landmarks also have personal details such as visits
      new maplibregl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          `<div style="margin-right: 20px;text-transform: capitalize;">
                    <a href="/tabs/home/landmark/${id}" style="color:var(--orange-1);text-decoration: none"><strong>${name}</strong></a>
                    <br/><span style="color:var(--text-medium);text-decoration: none">${type}</span>
                    <br/><a href="/tabs/home/landmark/${id}" style="color:var(--text-medium);text-decoration: none;font-weight: bold">${
            showVisits ? `${visits} ${visits === 1 ? "visit" : "visits"}` : ""
          }</a>
                 </div>`
        )
        .addTo(map);
    };
    map.addControl(new maplibregl.NavigationControl());
    map.on("click", "landmarks", handleLandmarkClick);
    map.on("click", "landmarks_dots", handleLandmarkClick);
    map.on("mouseenter", "landmarks", function () {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "landmarks", function () {
      map.getCanvas().style.cursor = "";
    });
    map.on("mouseenter", "landmarks_dots", function () {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "landmarks_dots", function () {
      map.getCanvas().style.cursor = "";
    });
  }
  componentWillUnmount() {
    if (this.map) {
      this.map.remove();
    }
  }

  togglefullScreen = () => {
    if (this.state.fullscreen) {
      this.setState({ fullscreen: false }, () => {
        this.map.resize();
      });

      // On iOS this doesn't exist so we need to check
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      }
      setTimeout(() => this.map.resize(), 100);
    } else {
      // On iOS this doesn't exist so we need to check
      if (this.mapContainer.current.requestFullscreen) {
        this.mapContainer.current.requestFullscreen();
      }

      // This seems to fix an issue where sometimes the map doesn't resize properly
      // Not great but it works... Tidy up when you can be bothered
      setTimeout(() => this.map.resize(), 10);

      this.setState({ fullscreen: true }, () => {
        this.map.resize();
      });
    }
  };

  render() {
    const areaId = this.props.area_id;
    return (
      <div
        className={
          // only apply fullscreen styles on iOS. This is because other platforms can just use the fullscreen API. iOS doesn't support this
          this.state.fullscreen && isIos()
            ? styles.fullscreen
            : this.props.className
        }
        ref={this.mapContainer}
        style={{ transform: "translateZ(-1)" }}
      >
        <div
          className={styles.fullscreenIcon}
          style={{
            transform: "translateZ(0)",
            backgroundImage: `url(${ImageManager.getImage(
              this.state.fullscreen
                ? ImageKeys.FULL_SCREEN_EXIT_ICON
                : ImageKeys.FULL_SCREEN_ICON
            )})`,
          }}
          onClick={this.togglefullScreen}
        />
      </div>
    );
  }
}
