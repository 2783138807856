import { Polygon } from "geojson";

export type ActivityType = {
  id: string;
  user_id: string;
  start_time: Date;
  vendor_data?: Object;
  type: string;
  start_latlng: string | null;
  end_latlng: string | null;
  moving_time: number;
  polyline?: string;
  vendor_activity_id: string;
  vendor: string;
  name: string;
  elevation_gain: string;
  distance: string;
  percent_new: string;
  bounding_box: Polygon;
  landmarks?: Array<LandmarkType>;
  neighbourhoods?: Array<NeighbourhoodSimple>;
};

export enum LandmarkTypeEnum {
  viewpoint = "viewpoint",
  artwork = "artwork",
  peak = "peak",
  memorial = "memorial",
  monument = "monument",
  archaeological_site = "archaeological_site",
  castle = "castle",
  lighthouse = "lighthouse",
  ruins = "ruins",
  dovecote = "dovecote",
  none = "",
}

export type LandmarkType = {
  landmark_id: string;
  area_id: string;
  name: string;
  type: LandmarkTypeEnum;
  way: Polygon;
  subtype: string | null;
  way_point?: Polygon;
  visits?: string;
  osm_id?: string;
};

export type VisitedLandmarkType = {
  activity_id: string;
  landmark_id: string;
  user_id: string;
};
/**
 * Node about polygon type . Geojson is needed when data is sent to the client. Postres geostrings are needed for mapnik
 */
export type Neighbourhood = {
  neighbourhood_id: string;
  name: string;
  polygon?: Object; // Careful. some queries return geojson and some return postgres's weird geostring things. This depends on the datas use
  area_id: string;
  total_landmarks: number;
  mapdb_id?: string;
};

export type NeighbourhoodSimple = Omit<Neighbourhood, "polygon">;

export type CityWithBB = {
  area_id: string;
  name: string;
  polygon: string; // Not geojson.. WKT format
  bounding_box: Polygon;
  total_roads_distance: string;
  total_off_roads_distance: string;
  total_landmarks: string;
  total_neighbourhoods: string;
  achievement_count: string;
  type: AreaType;
  user_id: string | null;
  parent_area_id: string | null;
};

export type CityWithBBSimple = Omit<CityWithBB, "polygon">;

export enum AreaType {
  city = "city",
  area = "area",
  custom = "custom",
  national_park = "national_park",
  scenic_area = "scenic_area",
}

export enum TimePeriods {
  all = "all",
  monthly = "monthly",
  year = "year",
}

export enum WaysType {
  all = "all",
  road = "road",
  trail = "trail",
}

export type UserNeighbourhoodBase = {
  user_neighbourhood_id: string;
  neighbourhood_id: string;
  user_id: string;
  percent_complete: string;
  percent_covered_month: string;
  percent_covered_year: string;
  percent_covered_roads: string;
  percent_covered_roads_year: string;
  percent_covered_roads_month: string;
  percent_covered_trails: string;
  percent_covered_trails_year: string;
  percent_covered_trails_month: string;
  landmarks_visited: string;
  user_city_id: string;
  activities_count: string;
  bounding_box: Polygon;
};

export type UserNeighbourhoodType = Neighbourhood & UserNeighbourhoodBase;

export type UserCityType = CityWithBB & {
  user_id: string;
  percent_covered: string;
  percent_covered_month: string;
  percent_covered_year: string;
  percent_covered_roads: string;
  percent_covered_roads_year: string;
  percent_covered_roads_month: string;
  percent_covered_trails: string;
  percent_covered_trails_year: string;
  percent_covered_trails_month: string;
  user_city_id: string;
  distance_covered: string;
  activities: string;
  landmarks_visited: string;
  achievements_complete: string;
  neighbourhoods_visited: string;
  date_added: string;
};

export type PercentagesType = {
  all: {
    [TimePeriods.all]: string;
    [TimePeriods.year]: string;
    [TimePeriods.monthly]: string;
  };
  trail: {
    [TimePeriods.all]: string;
    [TimePeriods.year]: string;
    [TimePeriods.monthly]: string;
  };
  road: {
    [TimePeriods.all]: string;
    [TimePeriods.year]: string;
    [TimePeriods.monthly]: string;
  };
};

export type WhatsNewType = {
  whats_new_id: string;
  title: string;
  description: string;
  version: string;
  image_url: string;
  url: string;
  date: string;
};
